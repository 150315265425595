var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d-virtual-table',{ref:"refillOrderTable",attrs:{"grid":_vm.grid,"columns":_vm.columns,"items":_vm.items,"with-pagination":"","is-body-slot":"","expanded":_vm.expanded,"virtual-scroll":false,"rows-per-page-options":[30, 50, 70]},on:{"update:expanded":function($event){_vm.expanded=$event},"on-pagination":function($event){return _vm.$emit('on-pagination', $event)}},scopedSlots:_vm._u([{key:"grid-item",fn:function(ref){
var props = ref.props;
return [_c('d-grid-card',{attrs:{"status-chip-props":_vm.chipStatusConfigBuilder(props.row.status),"item":props,"status-select-disable":_vm.checkIsDisable(props.row.status),"status-dictionary-fn":_vm.getStatusesByCurrentStatus},on:{"on-status":function($event){return _vm.$emit('on-status', $event, props.row.id)},"on-amount":function($event){return _vm.$emit('on-amount', {
          id: props.row.id,
          amount: $event,
          minLimit: props.row.minLimit,
          maxLimit: props.row.maxLimit,
        })},"on-customer-transaction-id":function($event){return _vm.$emit('on-customer-transaction-id', $event)},"on-update-requisite":function($event){return _vm.$emit('on-update-requisite', $event)}}})]}},{key:"body",fn:function(props){return [_c('q-tr',{attrs:{"props":props}},[_vm._l((props.cols),function(col){return [(col.name === 'details')?_c('q-td',{key:col.name,attrs:{"auto-width":"","props":props}},[_c('q-btn',{attrs:{"size":"sm","color":"accent","round":"","dense":"","unelevated":"","icon":!props.expand ? 'mdi-chevron-down' : 'mdi-chevron-up'},on:{"click":function($event){return props.expandRow(props.key)}}})],1):(col.name === 'customer-transaction-id')?_c('q-td',{key:col.name,attrs:{"auto-width":"","props":props}},[_c('q-btn',{attrs:{"color":"primary","size":"sm","no-caps":"","unelevated":"","disable":!_vm.canEditCustomerTransactionId({ status: props.row.status }),"outline":!_vm.canEditCustomerTransactionId({ status: props.row.status }),"label":col.value},on:{"click":function($event){return _vm.$emit('on-customer-transaction-id', {
                id: props.row.id,
                customerTransactionId: col.value,
              })}}}),_c('d-copy-btn',{staticClass:"q-ml-sm",attrs:{"value":col.value}})],1):(col.name === 'amount')?_c('q-td',{key:col.name,attrs:{"props":props}},[_c('q-btn',{staticClass:"amount-btn",attrs:{"color":"primary","label":col.format(props.row.amount),"disable":_vm.checkIsDisableAmountChange(props.row.status),"outline":_vm.checkIsDisableAmountChange(props.row.status),"unelevated":"","size":"sm"},on:{"click":function($event){return _vm.$emit('on-amount', {
                id: props.row.id,
                amount: props.row.amount,
                minLimit: props.row.minLimit,
                maxLimit: props.row.maxLimit,
              })}}})],1):(col.name === 'status')?_c('q-td',{key:col.name,attrs:{"props":props}},[_c('q-select',{attrs:{"disable":_vm.checkIsDisable(props.row.status),"dense":"","outlined":"","stack-label":"","value":props.row.status,"options":_vm.getStatusesByCurrentStatus(props.row.status)},on:{"input":function($event){return _vm.$emit('on-status', $event, props.row.id)}},scopedSlots:_vm._u([{key:"selected",fn:function(){return [_c('q-chip',_vm._b({staticClass:"q-my-xs q-ml-xs q-mr-none",attrs:{"dense":"","square":""}},'q-chip',_vm.chipStatusConfigBuilder(props.row.status),false))]},proxy:true}],null,true)})],1):_c('q-td',{key:col.name,attrs:{"props":props}},[_vm._v(" "+_vm._s(col.value)+" ")])]})],2),(props.expand)?_c('q-tr',{attrs:{"props":props}},[_c('q-td',{attrs:{"colspan":"100%"}},[_c('d-expanded-area',{attrs:{"value":props},on:{"on-update-requisite":function($event){return _vm.$emit('on-update-requisite', $event)}}})],1)],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }